import { countriesType, paths, socialImageType } from "lib/types";

// Master definition of path translations
export const localizedPaths = (language: countriesType): paths =>
  ({
    sv: {
      home: "",
      about: "om-useit",
      contact: "kontakta-oss",
      services: "vi-erbjuder",
      posts: "aktuellt",
      confirmation: "bekraftelse",
      newsletter: "nyhetsbrev",
      case: "kundcase",
      ecommerce: "hur-tillganglig-ar-ehandeln-2023",
    },
    no: {
      home: "",
      about: "om-useit",
      contact: "kontakt-oss",
      services: "vi-tilbyr",
      posts: "aktuelt",
      confirmation: "bekreftelse",
      newsletter: "nyhetsbrev",
      case: "kundecase",
      ecommerce: "hvor-universelt-utformet-er-ehandelen",
    },
    da: {
      home: "",
      about: "om-useit",
      contact: "kontakt",
      services: "vi-tilbyder",
      posts: "aktuelt",
      confirmation: "bekreftelse",
      newsletter: "nyhetsbrev",
      case: "kundecase",
      ecommerce: "hvor-universelt-utformet-er-ehandelen",
    },
    en: {
      home: "",
      about: "about",
      contact: "contact",
      services: "services",
      posts: "posts",
      confirmation: "confirmation",
      newsletter: "newsletter",
      case: "case",
      ecommerce: "ecommerce",
    },
  })[language];

// Function to get the correct URLs fron env variables
export const canonicalBaseLanguageURLs = (language: countriesType) =>
  ({
    sv: process.env.USEIT_SE_URL,
    no: process.env.USEIT_NO_URL,
    en: process.env.USEIT_EN_URL,
    da: process.env.USEIT_DK_URL,
  })[language];

export const relativeBaseLanguageURLs = (language: countriesType) => {
  const defaultLocale = process.env.DEFAULT_LOCALE?.split(",")[0];
  return language === defaultLocale ? "/" : `/${language}/`;
};

interface MetadataOptions {
  languages?: countriesType[];
  slug?: string;
}

export const getMetadataLanguageAlternatives = (
  route: keyof paths,
  { languages, slug }: MetadataOptions = {},
): Record<string, string> => {
  const availableLanguages = languages || process.env.LOCALES?.split(",") || [];
  return availableLanguages.reduce(
    (acc, country) => {
      acc[country.toLowerCase()] =
        `${canonicalBaseLanguageURLs(country as countriesType)}${localizedPaths(country as countriesType)[route]}${
          slug ? `/${slug}/` : ""
        }`;
      return acc;
    },
    {} as Record<string, string>,
  );
};

export const getArticleImage = (data: {
  socialImage?: socialImageType;
}): string => {
  let image = "";
  if (data.socialImage?.customSocialImage?.image) {
    image = data.socialImage?.customSocialImage?.image?.url;
  } else if (data.socialImage?.defaultSocialImage?.image) {
    image = data.socialImage?.defaultSocialImage.image;
  } else {
    image = "default_og_image.png";
  }
  return image;
};

export const getCompanyName = (locale: countriesType) =>
  ({
    en: "Useit",
    sv: "Useit Sverige",
    no: "Useit Norge",
    da: "Useit Danmark",
  })[locale || "sv"];

// eslint-disable-next-line import/no-unresolved
import type { AllLocales } from "next-intl/dist/types/src/shared/types";
import {
  createLocalizedPathnamesNavigation,
  Pathnames,
} from "next-intl/navigation";

export const locales = process.env.LOCALES?.split(",") as AllLocales;
export const siteLocales = process.env.SITE_LOCALES?.split(",");
export const localePrefix = "as-needed";

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
  // If all locales use the same pathname, a
  // single external path can be provided.
  "/": "/",

  // If locales use different paths, you can
  // specify each external path per locale.
  "/about": {
    en: "/about",
    sv: "/om-useit",
    no: "/om-useit",
    da: "/om-useit",
  },

  "/contact": {
    en: "/contact",
    sv: "/kontakta-oss",
    no: "/kontakt-oss",
    da: "/kontakt",
  },

  "/services": {
    en: "/services",
    sv: "/vi-erbjuder",
    no: "/vi-tilbyr",
    da: "/vi-tilbyder",
  },

  "/services/[slug]": {
    en: "/services/[slug]",
    sv: "/vi-erbjuder/[slug]",
    no: "/vi-tilbyr/[slug]",
    da: "/vi-tilbyder/[slug]",
  },

  "/case": {
    en: "/case",
    sv: "/kundcase",
    no: "/kundecase",
    da: "/kundecase",
  },

  "/case/[slug]": {
    en: "/case/[slug]",
    sv: "/kundcase/[slug]",
    no: "/kundecase/[slug]",
    da: "/kundecase/[slug]",
  },

  "/ecommerce": {
    en: "/ecommerce",
    sv: "/hur-tillganglig-ar-ehandeln-2023",
    no: "/hvor-universelt-utformet-er-ehandelen",
    da: "/ecommerce",
  },

  "/ecommerce/[slug]": {
    en: "/ecommerce/[slug]",
    sv: "/hur-tillganglig-ar-ehandeln-2023/[slug]",
    no: "/hvor-universelt-utformet-er-ehandelen/[slug]",
    da: "/ecommerce/[slug]",
  },

  "/newsletter": {
    en: "/newsletter",
    sv: "/nyhetsbrev",
    no: "/nyhetsbrev",
    da: "/nyhetsbrev",
  },

  "/confirmation": {
    en: "/confirmation",
    sv: "/bekraftelse",
    no: "/bekreftelse",
    da: "/bekraftelse",
  },

  "/posts": {
    en: "/posts",
    sv: "/aktuellt",
    no: "/aktuelt",
    da: "/aktuelt",
  },

  "/posts/[slug]": {
    en: "/posts/[slug]",
    sv: "/aktuellt/[slug]",
    no: "/aktuelt/[slug]",
    da: "/aktuelt/[slug]",
  },
} satisfies Pathnames<typeof locales>;

type AllPathnameKeys = keyof typeof pathnames;
export type PathnameKeys = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in AllPathnameKeys]: K extends `${infer R}/[slug]` ? never : K;
}[AllPathnameKeys];

export const { Link, getPathname, redirect, usePathname, useRouter } =
  createLocalizedPathnamesNavigation({ locales, localePrefix, pathnames });
